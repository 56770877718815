import { useContext, useEffect, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGridPro, GridColDef, GridFilterModel } from "@mui/x-data-grid-pro";
import {
  Stack,
  Tooltip,
  IconButton,
  Button,
  Chip,
  CircularProgress,
  Skeleton,
  styled,
  MenuProps,
  Menu,
  alpha,
} from "@mui/material";
import { Link as RLink, useNavigate, useSearchParams } from "react-router-dom";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { numberWithCommas } from "../../utils/formatMoney";
import {
  CheckCircle,
  Info,
  KeyboardArrowDownOutlined,
  PendingOutlined,
  RestorePageOutlined,
} from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Notes from "../../modals/LogNotes";
import moment from "moment";
import { UIContext } from "../../providers/UIProvider";
import {
  getIntentionOrderApi,
  changeOrderOptions,
  deleteChangeOrder,
  downloadChangeOrderPDF,
  JobHasSubmittedBasePlan,
  unSubmitIntention,
  updateKeyword,
  JobHasSubmittedIntention,
} from "../../apiCalls";
import SummaryCreateChangeOrder from "../../modals/SummaryCreateChangeOrder/SummaryCreateChangeOrder";
import ValidatedInput from "../../components/ValidatedInput";
import { capitalize, isNil, isString } from "lodash";
import { dispatchError } from "../../common/fx";
import "./JobSummary.scss";
import LoadingGridOverlay from "../../components/Loading/LoadingGridOverlay";
import PlanElevation from "../../modals/PlanElevation";
import useAccessControl from "../../hooks/useAccessControl";
import DeleteConfirmation from "../../modals/DeleteConfirmation";
import PlanChange from "../../modals/PlanChange";

function Copyright(props: any) {
  const applicationPrId = process.env.REACT_APP_APPLICATION_PR_ID;
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      sx={{ mt: (theme) => theme.spacing(12), mb: (theme) => theme.spacing(4) }}
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://highlandhomes.com/">
        Highland Homes
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
      <span>{applicationPrId}</span>
    </Typography>
  );
}

const theme = createTheme();

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function JobSummary() {
  const [state, dispatch] = useContext<any>(UIContext);
  const [hasSubmittedBasePlan, setHasSubmittedBasePlan] =
    useState<boolean>(false);
  const [hasSubmittedIntention, setHasSubmittedIntention] =
    useState<boolean>(false);
  const [downloading, setDownloading] = useState<{
    id: string;
    loading: boolean;
  }>({
    id: "",
    loading: false,
  });
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  // Filter out non-submitted objects
  const submittedChangeOrders = state.selectedJob.intentions.filter(
    (order: { status: "Submitted" | "Pending" }) => order.status === "Submitted"
  );

  // Find the object with the latest submitted timestamp
  const latestSubmittedChangeOrder = submittedChangeOrders.reduce(
    (latest: any, order: any) => {
      return new Date(order.submittedAt) > new Date(latest.submittedAt)
        ? order
        : latest;
    },
    submittedChangeOrders[0]
  );
  const DownloadChangeOrderAccess = useAccessControl(
    "ChangeOrder",
    "DownloadPDF"
  );
  const UpdateIntention = useAccessControl("Intention", "UpdateIntention");
  const DeleteIntention = useAccessControl("Intention", "DeleteIntention");
  const UpdateKeywordAccess = useAccessControl("Intention", "UpdateKeyword");
  const GetIntentionAccess = useAccessControl("Intention", "GetIntention");
  const UnsubmitIntentionAccess = useAccessControl(
    "Intention",
    "UnsubmitIntention"
  );
  const ElevationChange = useAccessControl("Intention", "ChangeElevation");
  const ModelFromExistingIntention = useAccessControl(
    "Intention",
    "ModelFromExistingIntention"
  );

  let addressId = searchParams.get("addressid");

  const [loading, setLoading] = useState<boolean>(true);
  const [confirmDelete, setConfirmDelete] = useState<{
    status: boolean;
    coNumber: "";
    id: "";
  }>({ status: false, coNumber: "", id: "" });

  const [confirmUndo, setConfirmUndo] = useState<{
    status: boolean;
    coNumber: "";
    id: "";
  }>({ status: false, coNumber: "", id: "" });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUpdateKeyword = (intentionId: string, newKeyword: string) => {
    updateKeyword(
      {
        intentionId: (intentionId as string) || "",
        keyword: newKeyword,
      },
      (res: any) => {
        const newJobSummary = { ...state.selectedJob };
        const intentionIx = state.selectedJob.intentions.findIndex(
          (intention: any) => intention.id === intentionId
        );
        newJobSummary.intentions[intentionIx].keyword = newKeyword;
        dispatch({
          type: "SelectedJob",
          payload: newJobSummary,
        });
      },
      (err: any) => dispatch(dispatchError(err.response.data))
    );
  };

  const fetchIntention = (success?: any) => {
    setLoading(true);
    getIntentionOrderApi(
      addressId as string,
      (res: any) => {
        dispatch({
          type: "SelectedJob",
          payload: {
            ...res.data,
            buyerLegalName: {
              contractBuyerNames: res.data.buyerLegalName,
              connectionNumber: res.data.buyerSalesforceConnectionNumber,
              salesforceAccountId: res.data.buyerSalesforceAccountId,
            },
          },
        });
        dispatch({
          type: "ChangeOrder",
          payload: {
            address: res.data.address,
            addressFreeform: "",
            community: {
              name: res.data.community,
              projectNumber: res.data.projectNumber,
            },
            customerBuyer: {
              buyerFirstName: res.data.buyerFirstName,
              buyerLastName: res.data.buyerLastName,
              buyerLegalName: res.data.buyerLegalName,
              contractBuyerNames: res.data.buyerLegalName,
              connectionId: res.data.buyerSalesforceAccountId,
              connectionNumber: res.data.buyerSalesforceConnectionNumber,
            },
            plan: {
              planNumber: res.data.planNumber,
              planId: res.data.planId,
              planElevationNumber: res.data.planElevationNumber,
            },
            elevation: {
              planElevationNumber: res.data.planElevationNumber,
              planElevationId: res.data.planElevationId,
            },
            keyword: "",
          },
          source: "Selected page Fetch COs",
        });
        dispatch({
          type: "Navbar",
          payload: {
            title: `Job Summary - ${
              res.data.addressType === "LotAndBlock"
                ? capitalize(res.data.lot + res.data.block)
                : res.data.address
            }`,
          },
        });
        JobHasSubmittedBasePlan(
          { jobId: res.data.jobId },
          (res: any) => setHasSubmittedBasePlan(res.data),
          (err: any) => dispatch(dispatchError(err.response.data))
        );
        JobHasSubmittedIntention(
          { jobId: res.data.jobId },
          (res: any) => setHasSubmittedIntention(res.data),
          (err: any) => dispatch(dispatchError(err.response.data))
        );
        success && success(res);
        setLoading(false);
      },
      (err: any) => {
        const errorMessage = err?.errors?.OperationCanceledException;

        if (
          (Array.isArray(errorMessage) &&
            errorMessage[0] ===
              "Change order not found or has been deleted.") ||
          errorMessage === "Change order not found or has been deleted."
        ) {
          navigate("/");
        }

        dispatch(dispatchError(err.response.data));
      }
    );
  };

  const handleDeleteRow = (id: any, changeOrderNumber: any) => {
    const updatedDelete = state.selectedJob.intentions.filter(
      (obj: { id: string }) => obj.id !== id
    );

    deleteChangeOrder(
      id,
      () => {
        dispatch({
          type: "SelectedJob",
          payload: {
            ...state.selectedJob,
            intentions: updatedDelete,
          },
        });
        dispatch({
          type: "Snackbar",
          payload: {
            show: true,
            message: `Change Order ${changeOrderNumber} has been deleted.`,
            severity: "success",
          },
        });
        if (updatedDelete.length === 0) {
          navigate(`/`);
        }
      },
      (err: any) => dispatch(dispatchError(err.response.data))
    );
  };

  const handleUnsubmitCO = (id: any, changeOrderNumber: any) => {
    /*const updatedDelete = state.selectedJob.intentions.filter(
      (obj: { id: string }) => obj.id !== id
    );*/

    UnsubmitIntentionAccess &&
      unSubmitIntention(
        { intentionId: id },
        () => {
          GetIntentionAccess && fetchIntention();
          dispatch({
            type: "Snackbar",
            payload: {
              show: true,
              message: `Change Order ${changeOrderNumber} has been unsubmitted.`,
              severity: "success",
            },
          });
          setConfirmUndo({ status: false, coNumber: "", id: "" });
        },
        (err: any) =>
          dispatch({
            type: "Snackbar",
            payload: {
              show: true,
              message: err?.response?.data?.errors?.ValidationException[0],
              severity: "error",
            },
          })
      );
  };

  const [filt, setFilt] = useState({
    items: [],
    quickFilterValues: [""],
  } as any);

  useEffect(() => {
    GetIntentionAccess && fetchIntention();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GetIntentionAccess]);

  const columns: GridColDef[] = [
    {
      field: "createdAt",
      headerName: "Create Date",
      flex: 1,
      width: 150,
      valueFormatter: (params) =>
        `${moment(params.value).format("MM-DD-YYYY")}`,
      renderCell: (params) => (
        <Tooltip
          placement="right-start"
          title={`Created On ${moment(params.row.createdAt).format(
            "MM-DD-YYYY"
          )} by  ${params.row.createdByName} `}
        >
          <span className="table-cell-trucate">
            {moment(params.row.createdAt).format("MM-DD-YYYY")}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "updatedAt",
      headerName: "Last Updated",
      flex: 1,
      width: 155,
      renderCell: (params) => (
        <Tooltip
          placement="right-start"
          title={`Last Updated On ${moment(params.row.updatedAt).format(
            "MM-DD-YYYY"
          )} by  ${params.row.lastUpdatedByName} `}
        >
          <span className="table-cell-trucate">
            {moment(params.row.updatedAt).format("MM-DD-YYYY")}
          </span>
        </Tooltip>
      ),
    },

    {
      field: "changeOrderNumber",
      headerName: "CO Number",
      flex: 1,
      width: 145,
      renderCell: (params) =>
        params?.row.status === "Submitted" ? (
          <RLink
            to={{
              pathname: "/view",
              search: `?intentionId=${params.row.id}`,
            }}
          >
            {params.row.changeOrderNumber}
          </RLink>
        ) : (
          <RLink
            to={{
              pathname: "/edit",
              search: `?intentionId=${params.row.id}`,
            }}
          >
            {params.row.changeOrderNumber}
          </RLink>
        ),
    },
    {
      field: "keyword",
      headerName: "Keyword",
      flex: 1,
      width: 140,
      renderCell: (params) => (
        <ValidatedInput
          className="grid-keyword"
          editTitle="Edit C.O. keyword"
          inputVariant="standard"
          mode="editor"
          disabled={!UpdateKeywordAccess}
          onChange={(newValue) => handleUpdateKeyword(params.row.id, newValue)}
          type="text"
          value={params.value}
          verify={false}
          saveTitle="Save this keyword"
        />
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      width: 140,

      renderCell: (params) => (
        <Chip
          icon={
            params.value === "Submitted" ? (
              <CheckIcon />
            ) : params.value === "Pending" ? (
              <PendingOutlined />
            ) : (
              <ErrorOutlineIcon />
            )
          }
          sx={{
            textTransform: "capitalize",
          }}
          label={params.value}
          variant="outlined"
        />
      ),
    },
    {
      field: "buyerName",
      headerName: "Buyer Name",
      flex: 1,
      width: 200,

      renderCell: (params) => (
        <Box
          sx={{
            textTransform: "capitalize",
          }}
        >
          {params.value}
        </Box>
      ),
    },
    {
      field: "salesforceConnectionNumber",
      headerName: "Connection Number",
      flex: 1,
      width: 200,
    },
    {
      field: "changeOrderPrice",
      headerName: "CO Price",
      flex: 1,
      width: 150,

      align: "right",
      headerAlign: "right",
      valueFormatter: (params) => `$ ${numberWithCommas(params.value)}`,
    },
    {
      field: "previousContractPrice",
      headerName: "Prior Price",
      flex: 1,
      width: 150,

      align: "right",
      headerAlign: "right",
      valueFormatter: (params) => `$ ${numberWithCommas(params.value)}`,
    },

    {
      field: "newContractPrice",
      headerName: "New Total Price",
      flex: 1,
      width: 175,

      align: "right",
      headerAlign: "right",
      renderCell: (params: any) =>
        params.row.status === "Pending"
          ? `$ ${numberWithCommas(
              params.row.previousContractPrice + params.row.changeOrderPrice
            )}`
          : `$ ${numberWithCommas(params.row.newContractPrice)}`,
    },
    {
      field: "actions",
      headerName: "",
      flex: 1,
      minWidth: 175,
      filterable: false,
      disableColumnMenu: true,
      sortable: false,
      hideable: true,
      pinnable: false,
      editable: false,
      align: "right",
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={0.5} mr={2}>
            {params.row.status === "submitted" ? (
              <>
                <Tooltip title="Download">
                  <IconButton onClick={() => handleDownloadItems(params.row)}>
                    {downloading.id !== params.row.id ? (
                      <DownloadIcon />
                    ) : (
                      <CircularProgress />
                    )}
                  </IconButton>
                </Tooltip>
              </>
            ) : (
              <>
                {params.row.status !== "Submitted" ? (
                  <Tooltip title="Edit">
                    <IconButton
                      disabled={!UpdateIntention}
                      onClick={() =>
                        navigate({
                          pathname: "/edit",
                          search: `?intentionId=${params.row.id}`,
                        })
                      }
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <>
                    {latestSubmittedChangeOrder.id === params?.row?.id &&
                      UnsubmitIntentionAccess && (
                        <Tooltip title="Unsubmit">
                          <IconButton
                            onClick={() =>
                              setConfirmUndo({
                                status: true,
                                coNumber: params?.row?.changeOrderNumber,
                                id: params?.row?.id,
                              })
                            }
                          >
                            <RestorePageOutlined />
                          </IconButton>
                        </Tooltip>
                      )}
                  </>
                )}
                {params.row.status !== "Submitted" && (
                  <Tooltip title="Delete">
                    <IconButton
                      disabled={!DeleteIntention}
                      onClick={() =>
                        setConfirmDelete({
                          status: true,
                          coNumber: params?.row?.changeOrderNumber,
                          id: params?.row?.id,
                        })
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                )}

                {params.row.status === "Submitted" && (
                  <Tooltip title="View">
                    <IconButton
                      onClick={() =>
                        navigate({
                          pathname: "/view",
                          search: `?intentionId=${params.row.id}`,
                        })
                      }
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title="Download">
                  <IconButton
                    disabled={downloading.loading}
                    onClick={() => handleDownloadItems(params.row)}
                  >
                    {downloading.id !== params.row.id ? (
                      <DownloadIcon />
                    ) : (
                      <CircularProgress size={"1.5rem"} />
                    )}
                  </IconButton>
                </Tooltip>
                {/* {params.row.status !== "Submitted" && (
                  <Tooltip title="Copy">
                    <IconButton
                    //onClick={() => handleDownloadItems(params?.row?.name as string)}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  </Tooltip>
                )} */}
              </>
            )}
          </Stack>
        );
      },
    },
    {
      field: "notes",
      headerName: "Notes",
      flex: 1,
      width: 125,

      renderCell: (params) => (
        <Tooltip title="View Notes">
          <Notes rows={params.row} />
        </Tooltip>
      ),
    },
  ];

  const handleDownloadItems = (changeOrderInfo: changeOrderOptions) => {
    setDownloading({
      id: changeOrderInfo.id,
      loading: true,
    });
    console.log(changeOrderInfo);
    DownloadChangeOrderAccess &&
      downloadChangeOrderPDF(
        { changeOrderId: changeOrderInfo.id },
        async (res: {
          fileData: "string";
          contentType: "string";
          documentName: "string";
        }) => {
          function base64ToBlob(
            base64: string,
            contentType: string = ""
          ): Blob {
            // Convert Base64 to a byte array
            const byteCharacters = atob(base64);
            const byteArrays = [];

            for (
              let offset = 0;
              offset < byteCharacters.length;
              offset += 512
            ) {
              const slice = byteCharacters.slice(offset, offset + 512);
              const byteNumbers = new Array(slice.length);

              for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
              }

              const byteArray = new Uint8Array(byteNumbers);
              byteArrays.push(byteArray);
            }

            // Create a blob from the byte array
            return new Blob(byteArrays, { type: contentType });
          }

          function downloadPDF(
            blobName: string,
            contentType: string,
            fileData: string
          ) {
            // Convert Base64 fileData to Blob
            const blob = base64ToBlob(fileData, contentType);

            // Create a Blob URL
            const blobUrl = window.URL.createObjectURL(blob);

            // Create a link element
            const link = document.createElement("a");

            // Set the download attribute with a filename
            link.download = blobName;

            // Set the href to the blob URL
            link.href = blobUrl;

            // Append the link to the document body
            document.body.appendChild(link);

            // Programmatically click the link to trigger the download
            link.click();

            // Clean-up: remove the link from the document
            document.body.removeChild(link);
          }

          downloadPDF(res.documentName, res.contentType, res.fileData);
          setDownloading({
            id: "",
            loading: false,
          });
        },
        (err: any) => {
          dispatch(dispatchError(err.response.data));
          setDownloading({
            id: "",
            loading: false,
          });
        }
      );
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth={false}>
        <CssBaseline />
        <Stack
          flexDirection={"row"}
          gap={2}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Stack
            alignItems={"center"}
            alignContent={"center"}
            gap={2}
            mb={2}
            mt={2}
            flexDirection={"row"}
            sx={{
              alignItems: "top",
              border: 1,
              borderColor: (mainTheme) => mainTheme.palette.grey[300],
              borderTop: 4,
              borderRadius: "10px",
              width: "100%",
              borderTopColor: (mainTheme) => mainTheme.palette.primary.main,
              justifyContent: { xs: "center", md: "space-between" },
              flexDirection: { xs: "column", md: "row" },
              maxWidth: { xs: "100%", lg: "60%" },
              py: 0.5,
              px: 2,
            }}
          >
            <div className="summary-details">
              <p style={{ margin: "0 auto .5rem" }}>
                <strong>Buyer:</strong>
              </p>
              <p className="summary-details--info">
                {state?.selectedJob?.buyerSalesforceAccountId ? (
                  <CheckCircle className="summary-icon success" />
                ) : (
                  <span title="Click Edit to assign buyer from Salesforce in order to Submit">
                    <Info className="summary-icon info" />
                  </span>
                )}
                <span
                  className={`${
                    state?.selectedJob?.buyerName ? "summary-buyer--legal" : ""
                  }`}
                  title={state?.selectedJob?.buyerLegalName?.contractBuyerNames}
                >
                  {state?.selectedJob?.buyerType === "spec" && "Spec"}
                  {!isNil(state?.selectedJob?.buyerName) &&
                  isString(state?.selectedJob?.buyerName) ? (
                    state?.selectedJob?.buyerName
                  ) : (
                    <Skeleton
                      variant="text"
                      sx={{ minWidth: "5rem", fontSize: "1rem" }}
                    />
                  )}
                  {state?.selectedJob?.buyerType !== "spec" &&
                    !isNil(state?.selectedJob?.buyerName) &&
                    !isString(state?.selectedJob?.buyerName) &&
                    `${state?.selectedJob?.buyerName} ${state?.selectedJob?.buyerName}`}
                </span>
              </p>
            </div>
            <div className="summary-details">
              <p className="summary-details--title">Community:</p>
              <p className="summary-details--info">
                {state?.selectedJob?.community ?? (
                  <Skeleton
                    variant="text"
                    sx={{ minWidth: "8rem", fontSize: "1rem" }}
                  />
                )}
              </p>
            </div>
            <div className="summary-details">
              <p className="summary-details--title">Address:</p>
              <p className="summary-details--info">
                {state.selectedJob.jobId ? (
                  <CheckCircle className="summary-icon success" />
                ) : (
                  <span title="Click Edit to assign job from BRIX in order to Submit">
                    <Info className="summary-icon info" />
                  </span>
                )}
                {state?.selectedJob?.address ?? (
                  <Skeleton
                    variant="text"
                    sx={{ minWidth: "8rem", fontSize: "1rem" }}
                  />
                )}
              </p>
            </div>
            <div className="summary-details">
              <p className="summary-details--title">Plan / Elevation:</p>
              <p className="summary-details--info">
                {state?.selectedJob?.planNumber ?? (
                  <Skeleton
                    variant="text"
                    sx={{
                      marginRight: "1px",
                      minWidth: "2rem",
                      fontSize: "1rem",
                    }}
                  />
                )}
                &nbsp; /&nbsp;
                {state?.selectedJob?.planElevationNumber ?? (
                  <Skeleton
                    variant="text"
                    sx={{ minWidth: "2rem", fontSize: "1rem" }}
                  />
                )}
              </p>
            </div>
          </Stack>
          <div>
            {ElevationChange && (
              <>
                <Button
                  id="demo-customized-button"
                  aria-controls={openMenu ? "demo-customized-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openMenu ? "true" : undefined}
                  variant="contained"
                  disableElevation
                  onClick={handleClick}
                  endIcon={<KeyboardArrowDownOutlined />}
                >
                  Action Menu
                </Button>
                <StyledMenu
                  id="demo-customized-menu"
                  MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                  }}
                  anchorEl={anchorEl}
                  open={openMenu}
                  onClose={handleClose}
                >
                  <PlanElevation
                    addressId={addressId}
                    disabled={hasSubmittedIntention && !hasSubmittedBasePlan}
                    title={
                      !hasSubmittedIntention && !hasSubmittedBasePlan
                        ? "Elevation Update"
                        : "Elevation Change"
                    }
                    updateElevationMode={
                      !hasSubmittedIntention && !hasSubmittedBasePlan
                    }
                    fetchIntention={GetIntentionAccess && fetchIntention}
                    handleDownloadItems={handleDownloadItems}
                  />
                  <PlanChange
                    addressId={addressId}
                    disabled={!hasSubmittedBasePlan}
                    title={"Plan Change"}
                    fetchIntention={GetIntentionAccess && fetchIntention}
                    handleDownloadItems={handleDownloadItems}
                  />
                </StyledMenu>
              </>
            )}
          </div>
          <Box>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() =>
                navigate(
                  `/joboptions-summary?addressId=${addressId}&jobID=${state.selectedJob.jobId}&planId=${state.selectedJob.planId}&ProjectId=${state.selectedJob.projectId}&PlanElevationId=${state.selectedJob.planElevationId}&projectNumber=${state.selectedJob.projectNumber}&jobNumber=${state.selectedJob.jobNumber}`
                )
              }
            >
              View Options Summary
            </Button>
          </Box>
          <Box>
            {ModelFromExistingIntention && (
              <SummaryCreateChangeOrder
                modalTitle={`Create Change Order For ${
                  state?.selectedJob?.addressType === "LotAndBlock"
                    ? capitalize(
                        state?.selectedJob?.lot + state.selectedJob.block
                      )
                    : state?.selectedJob?.address
                }`}
                buttonTitle={"Create Change Order"}
                createButtonTitle={"Create"}
                intentionId={addressId as string}
              />
            )}
          </Box>
        </Stack>
        <DeleteConfirmation
          actionText="Confirm"
          setIsOpen={() =>
            setConfirmUndo({ status: false, coNumber: "", id: "" })
          }
          deleteMessage={`Unsubmit the Change Order: ${confirmUndo.coNumber} ?`}
          deleteTitle={"Unsubmit Change Order"}
          onClose={() =>
            setConfirmUndo({ status: false, coNumber: "", id: "" })
          }
          isOpen={confirmUndo.status}
          confirmDelete={() =>
            handleUnsubmitCO(confirmUndo.id, confirmUndo.coNumber)
          }
        />
        <DeleteConfirmation
          setIsOpen={() =>
            setConfirmDelete({ status: false, coNumber: "", id: "" })
          }
          deleteMessage={`Delete the Change Order: ${confirmDelete.coNumber} ?`}
          deleteTitle={"Delete Change Order"}
          onClose={() =>
            setConfirmDelete({ status: false, coNumber: "", id: "" })
          }
          isOpen={confirmDelete.status}
          confirmDelete={() => {
            setConfirmDelete({ status: false, coNumber: "", id: "" });
            handleDeleteRow(confirmDelete.id, confirmDelete.coNumber);
          }}
        />
        <>
          <DataGridPro
            scrollbarSize={10}
            sx={{
              marginTop: (theme) => theme.spacing(2),
              height: "100%",
              minHeight: "20rem",
              "& .MuiDataGrid-virtualScroller": {
                transform: "rotateX(180deg)",
              },
              "& .MuiDataGrid-virtualScrollerContent": {
                transform: "rotateX(180deg)",
              },
            }}
            initialState={{
              pagination: { paginationModel: { pageSize: 25 } },
              sorting: {
                sortModel: [{ field: "changeOrderNumber", sort: "asc" }],
              },
            }}
            disableRowSelectionOnClick
            filterModel={filt as GridFilterModel | undefined}
            onFilterModelChange={(newFilterModel) =>
              setFilt(newFilterModel as any)
            }
            columns={columns}
            rows={state.selectedJob.intentions}
            rowCount={state.selectedJob.intentions.length}
            pagination
            slots={{
              loadingOverlay: LoadingGridOverlay,
            }}
            loading={loading}
          />
        </>

        <Box
          sx={{
            mt: (theme) => theme.spacing(2),
            backgroundColor: "white",
          }}
        >
          <RLink to="/">
            <Button variant="outlined">Back</Button>
          </RLink>
        </Box>
        <Copyright />
      </Container>
    </ThemeProvider>
  );
}
