import { useState, useEffect, useContext } from "react";
import ReusableDialog from "../../components/Dialog";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Stack,
} from "@mui/material";
import { DesignServices, Home, Houseboat } from "@mui/icons-material";

import {
  ChangeElevation,
  ChangePlan,
  downloadChangeOrderPDF,
  getOptionsCatalog,
  getPlanElevations,
  getPlans,
  GetSubmittedBasePlan,
  UpdateElevation,
} from "../../apiCalls";
import { PlanTypes, UIContext, UIState } from "../../providers/UIProvider";
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import { dispatchError } from "../../common/fx";
import { isEmpty, isNil } from "lodash";
import moment from "moment";
import useAccessControl from "../../hooks/useAccessControl";

export type homeInformation = {
  plan: {
    planId: string;
    planNumber: string;
  };
  elevation: {
    price: number;
    planElevationNumber: string;
    projectID: string;
    planElevationID: string;
    salesPriceControlId: string;
  };
};

const PlanChange = ({ fetchIntention, disabled, addressId, title }: any) => {
  const [currentPrice, setCurrentPrice] = useState("");
  const [priceDifference, setPriceDifference] = useState("");
  const [planInfo, setPlanInfo] = useState({ id: "" });
  const GetPlansAccess = useAccessControl("Plan", "GetPlans");
  const GetPlanElevationsAccess = useAccessControl("Plan", "GetPlanElevations");
  const GetOptionsCatalogAccess = useAccessControl(
    "Option",
    "GetOptionsCatalog"
  );
  const DownloadChangeOrderAccess = useAccessControl(
    "ChangeOrder",
    "DownloadPDF"
  );
  const [loadingCalculation, setLoadingCalculation] = useState(true);
  const [state, dispatch] = useContext<UIState | any>(UIContext);
  const [open, setOpen] = useState<boolean>(false);
  const [specBuyer, setSpecBuyer] = useState<boolean>(false);

  const [newHomeInformation, setNewHomeInformation] = useState<homeInformation>(
    {
      plan: {
        planNumber: "",
        planId: "",
      },
      elevation: {
        price: 0,
        planElevationNumber: "",
        projectID: "",
        planElevationID: "",
        salesPriceControlId: "",
      },
    }
  );

  const [currentStep, setCurrentStep] = useState<number>(1);

  useEffect(() => {
    if (state.selectedJob.planId && state.selectedJob.planNumber) {
      GetPlansAccess &&
        getPlans(
          {
            projectID: state.selectedJob.projectId,
            projectNumber: state.selectedJob.projectNumber,
          },
          (res: any) => {
            dispatch({
              type: "AvailablePlans",
              payload: res.data.sort(
                (
                  a: {
                    planNumber: string;
                  },
                  b: {
                    planNumber: string;
                  }
                ) => a.planNumber.localeCompare(b.planNumber)
              ),
            });
          },
          (err: any) => dispatch(dispatchError(err.response.data))
        );
      GetPlanElevationsAccess &&
        getPlanElevations(
          {
            ProjectID: state.selectedJob.projectId,
            projectNumber: state.selectedJob.projectNumber,
            planId: state.selectedJob.planId,
            planNumber: state.selectedJob.planNumber,
          },
          (res: any) => {
            dispatch({
              type: "AvailableElevations",
              payload: res.data.sort(
                (
                  a: {
                    planElevationNumber: string;
                  },
                  b: {
                    planElevationNumber: string;
                  }
                ) => a.planElevationNumber.localeCompare(b.planElevationNumber)
              ),
            });
          },
          (err: any) =>
            dispatch(
              dispatchError({
                message: err.message,
                statusText: err.response.statusText,
                title: err.response.data.title,
                status: err.response.status,
                detail: err.response.data.detail,
                data: err.response.data,
              })
            )
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.selectedJob, GetPlansAccess]);

  const toggleStep = () => {
    switch (currentStep) {
      case 1:
        return setCurrentStep(2);

      case 2:
        return (
          ChangePlan(
            {
              planElevationOptionId: planInfo?.id,
              newPlanElevationSalesPriceControlId:
                newHomeInformation?.elevation.salesPriceControlId.toString(),
              expectedPriceDifference: priceDifference.toString(),
              useSpecBuyer: specBuyer,
            },
            async (res: any) =>
              await fetchIntention((coUpdate: any) => {
                dispatch({
                  type: "Snackbar",
                  payload: {
                    show: true,
                    message: `${state.selectedJob.address} plang has been updated. Remember to submit the change order.`,
                    severity: "success",
                  },
                });
                DownloadChangeOrderAccess &&
                  res.data.map((id: string) =>
                    downloadChangeOrderPDF(
                      { changeOrderId: id },
                      async (res: {
                        fileData: "string";
                        contentType: "string";
                        blobName: "string";
                        documentName: "string";
                      }) => {
                        function base64ToBlob(
                          base64: string,
                          contentType: string = ""
                        ): Blob {
                          // Convert Base64 to a byte array
                          const byteCharacters = atob(base64);
                          const byteArrays = [];

                          for (
                            let offset = 0;
                            offset < byteCharacters.length;
                            offset += 512
                          ) {
                            const slice = byteCharacters.slice(
                              offset,
                              offset + 512
                            );
                            const byteNumbers = new Array(slice.length);

                            for (let i = 0; i < slice.length; i++) {
                              byteNumbers[i] = slice.charCodeAt(i);
                            }

                            const byteArray = new Uint8Array(byteNumbers);
                            byteArrays.push(byteArray);
                          }

                          // Create a blob from the byte array
                          return new Blob(byteArrays, { type: contentType });
                        }

                        function downloadPDF(
                          blobName: string,
                          contentType: string,
                          fileData: string
                        ) {
                          // Convert Base64 fileData to Blob
                          const blob = base64ToBlob(fileData, contentType);

                          // Create a Blob URL
                          const blobUrl = window.URL.createObjectURL(blob);

                          // Create a link element
                          const link = document.createElement("a");

                          // Set the download attribute with a filename
                          link.download = blobName;

                          // Set the href to the blob URL
                          link.href = blobUrl;

                          // Append the link to the document body
                          document.body.appendChild(link);

                          // Programmatically click the link to trigger the download
                          link.click();

                          // Clean-up: remove the link from the document
                          document.body.removeChild(link);
                        }

                        const latestCO =
                          coUpdate.data.intentions
                            .filter(
                              (order: { submittedAt: "string" }) =>
                                order.submittedAt !== null
                            )
                            .sort(
                              (
                                a: { submittedAt: "string" },
                                b: { submittedAt: "string" }
                              ) =>
                                moment(b.submittedAt).diff(
                                  moment(a.submittedAt)
                                )
                            )[0] || null;

                        downloadPDF(
                          res.documentName,
                          res.contentType,
                          res.fileData
                        );
                      },
                      (err: any) => {
                        dispatch(
                          dispatchError({
                            message: err.message,
                            statusText: err.response.statusText,
                            title: err.response.data.title,
                            status: err.response.status,
                            detail: err.response.data.detail,
                            data: err.response.data,
                          })
                        );
                      }
                    )
                  );
              }),
            (err: any) => dispatch(dispatchError(err.response.data))
          ),
          setCurrentStep(1),
          setOpen(false)
        );
    }
  };

  return (
    <>
      <ReusableDialog
        setIsOpen={setOpen}
        isOpen={open}
        toolTipTitle={
          disabled
            ? "Must have a submitted CO with a base plan option to change elevation."
            : undefined
        }
        disabled={disabled}
        buttonVariant={"text"}
        buttonStyle={{
          color: "black",
          paddingRight: "3rem",
        }}
        buttonText={title}
        icon={
          <DesignServices
            sx={{
              marginRight: "5px",
            }}
          />
        }
        maxWidth="sm"
        title={currentStep === 1 ? title : "Confirm Change"}
        content={
          <>
            <DialogContent>
              <Box p={2}>
                {currentStep === 1 && state.selectedJob.planNumber && (
                  <Step1
                    setSpecBuyer={setSpecBuyer}
                    newHomeInformation={newHomeInformation}
                    setNewHomeInformation={setNewHomeInformation}
                  />
                )}
                {currentStep === 2 && (
                  <Step2
                    priceDifference={priceDifference}
                    setPriceDifference={setPriceDifference}
                    setPlanInfo={setPlanInfo}
                    loadingCalculation={loadingCalculation}
                    setLoadingCalculation={setLoadingCalculation}
                    currentPrice={currentPrice}
                    setCurrentPrice={setCurrentPrice}
                    newHomeInformation={newHomeInformation}
                  />
                )}
              </Box>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "space-between" }}>
              <Box>
                {currentStep === 2 && (
                  <Button
                    onClick={() => {
                      setCurrentStep(1);
                    }}
                    variant="outlined"
                    color="primary"
                  >
                    Back
                  </Button>
                )}
              </Box>
              <Stack direction="row" spacing={1}>
                <Button
                  onClick={() => {
                    setOpen(false);
                    setCurrentStep(1);
                  }}
                  variant="outlined"
                  color="primary"
                >
                  Cancel
                </Button>

                <Button
                  disabled={
                    isEmpty(newHomeInformation.elevation.planElevationNumber) ||
                    isEmpty(newHomeInformation.plan.planNumber)
                  }
                  onClick={(e) => toggleStep()}
                  variant="contained"
                  color="primary"
                >
                  {currentStep === 1 ? "Next" : "Submit"}
                </Button>
              </Stack>
            </DialogActions>
          </>
        }
      />
    </>
  );
};

export default PlanChange;
