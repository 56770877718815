import { useState, useEffect, useContext, ReactNode } from "react";
import { dispatchError } from "../../common/fx";
import { UIState, UIContext } from "../../providers/UIProvider";
import { Box, Button, Dialog, DialogActions, DialogContent, InputAdornment, TextField, Stack } from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid-pro";
import { AddComment } from "@mui/icons-material";
import { DeleteWizard } from "../../assets/icons/hhIcons";


export default function TextInputCell(props: any) {
  const getValue = (currentValue: any) => {
    return String(currentValue).substring(0, 1) === "$"
      ? parseFloat(String(currentValue).substring(2).replace(",", ""))
      : currentValue;
  };

  const {
    align,
    field,
    formattedValue,
    id,
    maxLength,
    max,
    min,
    setRows,
    Rows,
    disabled,
    type,
    placeholder,
  } = props;
  const [value, setValue] = useState<any>(getValue(formattedValue));
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, dispatch] = useContext<UIState | any>(UIContext);
  const [isShifted, setIsShifted] = useState<boolean>(false);
  const apiRefContext = useGridApiContext();
  const [clDialog, setClDialog] = useState<boolean>(false);
  const [clDialogImg, setClDialogImg] = useState<string | null>(null);
  const [clDialogBody, setClDialogBody] = useState<ReactNode | null>(null);
  const [clDialogShown, setclDialogShown] = useState<boolean>(false);

  useEffect(() => {
    setValue(getValue(formattedValue));
  }, [formattedValue]);

  const handleFocus = (event: any) => {
    setIsShifted(false);
    if (field === "quantity" && (value === 1 || value === "1")) {
      setValue("");
      return;
    }
    if (field === "unitPrice" && (value === 0 || value === "0")) {
      setValue("");
      return;
    }
    event.target.select();
  };

  const handleChange = (event: any) => {
    const newValue = event.target.value;
    if(field === "description") {
      if(newValue.length === 500) {
        setClDialogImg("thisdude.jpg");
        setClDialogBody(<p style={{ margin: "0" }}>500 characters?!!<br />
          &lt;- Are you this dude?<br />
          Are you writing a novel??<br />
          Use the <strong>Notes</strong> <AddComment color="primary" /> instead!</p>);
        if(!clDialogShown) setClDialog(true);
        setclDialogShown(true);
      }

      const lowerCasedValue = newValue.toLowerCase();
        if(lowerCasedValue.includes("delete") ||
          lowerCasedValue.includes("cancel") ||
          lowerCasedValue.includes("remove") ||
          lowerCasedValue.includes("void") ||
          lowerCasedValue.includes("bust") ||
          lowerCasedValue.includes("dirt")) {
          setClDialogImg(null);
          setClDialogBody(
            <>
              <p style={{ margin: "0 0 1.75rem" }}>To correctly delete previously submitted options,
                please use the <strong>Delete Wizard</strong>
                &nbsp;<Button color="primary" variant="outlined" sx={{ minWidth: "30px", padding: "4px 2px", width: "30px" }}><DeleteWizard /></Button>
              </p>
              <p style={{ margin: "0"}}>
                Or go to the <strong>Options Summary</strong> screen
                and select the submitted options then click <Button color="primary" variant="contained">Delete with CO</Button>
              </p>
            </>);
          if(!clDialogShown) setClDialog(true);
          setclDialogShown(true);
        }
    }
    setValue(newValue);
  };

  const handleBlur = (event: any) => {
    let newValue: any = value;
    if (typeof newValue === "string") {
      newValue = newValue.trim();
      setValue(newValue);
    }
    switch (field) {
      case "optionCode":
        newValue =
          newValue === null ? "" : String(newValue).toUpperCase();
        break;
      case "description":
        newValue = newValue === null ? "" : String(newValue);
        break;
      case "quantity":
        if (newValue === null || String(newValue) === "") {
          newValue = 1;
          setValue(1);
        } else if (newValue < min || newValue > max) {
          dispatch(
            dispatchError({
              message: "Quantity",
              statusText: `Quantity value is invalid`,
              data: "Please use a valid integer value (1-999)",
              title: `'${newValue}' on quantity field is invalid`,
              detail: "Please use a valid integer value (1-999)",
            })
          );
          newValue = min;
          setValue(min);
        } else newValue = parseInt(newValue);
        break;
      case "unitPrice":
        if (newValue === null || String(newValue) === "") {
          newValue = 0;
          setValue(0);
        } else if (newValue < min || newValue > max) {
          dispatch(
            dispatchError({
              message: "Unit Price",
              statusText: `Unit price is invalid`,
              data: "Please use a valid amount",
              title: `'${newValue}' on Unit Price is invalid`,
              detail: "Please use a valid amount",
            })
          );
          newValue = 0;
          setValue(0);
        } else newValue = parseFloat(newValue).toFixed(2);
    }

    const updatedArray = Rows.map((obj: any) => {
      if (obj.id === id) {
        const newObj = {
          ...obj,
          [field]: newValue,
        };
        if (obj.field === "optionCode" || field === "description") {
          newObj.salesPriceControlID = 0;
        }
        return newObj;
      }
      return obj;
    });

    setRows({
      type: "ChangeOrderPending",
      payload: updatedArray,
      source: "TextInput onBlur",
    });

    if (field === "optionCode") {
      apiRefContext.current.setEditCellValue({
        id,
        field,
        value: newValue,
      });
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <TextField
        disabled={disabled}
        fullWidth
        id={`${id}_${field}`}
        inputProps={{
          style: {
            textAlign: align ? align : undefined,
            padding: "4px 16px 5px",
          },
          maxLength: maxLength ? maxLength : undefined,
          max: max !== null && max !== undefined ? max : undefined,
          min: min !== null && min !== undefined ? min : undefined,
        }}
        InputProps={{
          startAdornment: field === "unitPrice" && (
            <InputAdornment position="start">$</InputAdornment>
          ),
        }}
        onChange={handleChange}
        onFocus={handleFocus}
        onKeyDown={(event) => {
          if (event.key === "Shift") {
            setIsShifted(true);
          }
          if (event.key === "Tab") {
            const myTarget: any = event.target;
            const myId = myTarget.id?.split("_")[0];
            let nextTabId: string = "";
            switch (field) {
              case "optionCode":
                nextTabId = isShifted
                  ? `${myId}_option`
                  : `${myId}_description`;
                break;
              case "description":
                if(props.row.option === "Formatting Separator") {
                  nextTabId = isShifted
                    ? `${myId}_option`
                    : `${myId}_addrow`;
                } else {
                  nextTabId = isShifted
                  ? `${myId}_optionCode`
                  : `${myId}_quantity`;
                }
                break;
              case "quantity":
                if (isShifted) {
                  nextTabId = `${myId}_description`;
                } else if (
                  props.row.option === "Manual Option" ||
                  props.row.manualPrice === true
                ) {
                  nextTabId = `${myId}_unitPrice`;
                } else {
                  nextTabId = `${myId}_addrow`;
                }
                break;
              case "unitPrice":
                nextTabId = isShifted
                  ? (nextTabId = `${myId}_quantity`)
                  : `${myId}_addrow`;
                break;
            }
            if (nextTabId) {
              const nextElement = document.getElementById(nextTabId);
              nextElement?.focus();
            }
          }
        }}
        onKeyUp={(event) => {
          if (event.key === "Shift") {
            setIsShifted(false);
          }
        }}
        onBlur={handleBlur}
        placeholder={placeholder}
        type={type}
        value={value}
        variant="standard"
      />
      { clDialog && 
        <Dialog
          open={clDialog}
          onClose={() => setClDialog(false)}
        >
          <DialogContent>
            <Stack direction="row">
              { clDialogImg && <img src={clDialogImg} style={{ height: "140px", width: "auto"}} /> }
              <div style={{ paddingLeft: "10px"}}>
                {clDialogBody}
              </div>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setClDialog(false)}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      }
    </Box>
  );
}
